import { defineStore } from "pinia";
import { ref } from "vue";
import { loadGoogleFontsUrls } from "@leon-hub/fonts";
import { nextAnimationFrame } from "@leon-hub/html-utils";
const useFontsStore = /* @__PURE__ */ defineStore("fonts", () => {
  const isFontsLoaded = ref(false);
  async function loadFonts() {
    if (!process.env.VUE_APP_PRERENDER) {
      try {
        await loadGoogleFontsUrls();
      } finally {
        await nextAnimationFrame();
        isFontsLoaded.value = true;
      }
    } else {
      isFontsLoaded.value = true;
    }
  }
  return {
    isFontsLoaded,
    loadFonts
  };
});
export {
  useFontsStore
};
