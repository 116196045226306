function isElementInsideParent(targetNode, parentNode) {
  if (targetNode === parentNode) {
    return true;
  }
  while (targetNode.parentElement && targetNode !== document.body) {
    targetNode = targetNode.parentElement;
    if (targetNode === parentNode) {
      return true;
    }
  }
  return false;
}
function isInFrame() {
  if (process.env.VUE_APP_RENDERING_SSR) {
    return false;
  }
  try {
    return window.self !== window.top;
  } catch {
  }
  return false;
}
function nextAnimationFrame() {
  return new Promise((resolve) => {
    if (process.env.NODE_ENV === "test") {
      resolve();
      return;
    }
    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        resolve();
      });
    });
  });
}
function prepareSiteConfigHtml(value) {
  const regexp = /^[\S\s]*<body[^>]*>([\S\s]*)<\/body>[\S\s]*$/im;
  const match = regexp.exec(value);
  return match !== null ? match[1] : value;
}
export {
  isElementInsideParent,
  isInFrame,
  nextAnimationFrame,
  prepareSiteConfigHtml
};
