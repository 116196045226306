import { QueryParameterName } from '@leon-hub/query-manager';
import { getQueryParams } from '@leon-hub/service-locator-env';

import { ApplicationGqlClient } from '@core/app-rest-client';

export function createGraphqlClient() {
  const ssrHeaders = process.env.VUE_APP_RENDERING_SSR
    // @ts-expect-error nuxt types are disabled!
    ? useRequestHeaders()
    : {};
  const runtimePlatform = getQueryParams()[QueryParameterName.PLATFORM];
  const gqlClient = new ApplicationGqlClient({
    headers: {
      ...runtimePlatform
        ? {
          // @see LEONWEB-6203
            'X-APP-PLATFORM': runtimePlatform,
          }
        : {},
      ...ssrHeaders,
    },
    // Set origin to same page url for prerender,
    // because default origin is defined with ?prhost query param.
    origin: process.env.VUE_APP_PRERENDER ? '' : undefined,
  });

  // TODO: mv condition to function.
  if (getQueryParams()[QueryParameterName.TEST_CASE] === 'performance') {
    gqlClient.setDefaultCacheTtl(Infinity);
    gqlClient.setMaxBatchQueueSize(1);
  }
  return gqlClient;
}
