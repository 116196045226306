import { defineStore } from "pinia";
import { toRef, ref, computed, readonly } from "vue";
import { getRootSettingsInternational, getRootSettings, doSaveLog, RequestOptionsPriority, LogLevel } from "@leon-hub/api-sdk";
import { assert, isArray } from "@leon-hub/guards";
import { useLocalStorageManager } from "@leon-hub/local-storage";
import { Locale } from "@leon-hub/locale";
import { QueryParameterName } from "@leon-hub/query-manager";
import { getBuiltinConfig, getLocationPath, getLocationHref, isOnline } from "@leon-hub/service-locator-env";
import { requestIdleCallback } from "@leon-hub/utils";
import { useGraphqlClient, useBaseApiClient, setApiHeaders } from "@core/app-rest-client";
import { useAuthStore } from "@core/auth";
import { useCountryStore } from "@core/country";
import { useFontsStore } from "@core/fonts";
import { useI18nStore } from "@core/i18n";
import { useSyncState } from "@core/sync-state";
import { useQueryManager } from "@core/query-manager";
import noop from "lodash/noop";
var ApiKeys = /* @__PURE__ */ ((ApiKeys2) => {
  ApiKeys2["api1Url"] = "API_1_URL";
  ApiKeys2["api2Url"] = "API_2_URL";
  ApiKeys2["api3Url"] = "API_3_URL";
  return ApiKeys2;
})(ApiKeys || {});
function getAppAdHeader() {
  const appAdHeader = useQueryManager().getParameter(QueryParameterName.APP_AD);
  if (appAdHeader) {
    return appAdHeader;
  }
  const initConfig = getBuiltinConfig();
  const adPaths = initConfig == null ? void 0 : initConfig.adPaths;
  if (adPaths) {
    const pathname = getLocationPath();
    for (const [key, value] of Object.entries(adPaths)) {
      if (pathname.includes(key)) {
        return value;
      }
    }
  }
  return null;
}
function getLanguageFlagCode(locale) {
  return locale.split("_")[0];
}
function makeRootSettingsRequest(silent) {
  const queryManager = useQueryManager();
  const headers = !silent ? queryManager.getRootHeaderParameters() : void 0;
  return doRootSettingsRequest(
    headers,
    silent
  );
}
function doRootSettingsRequest(headers, silent) {
  const apiClient = useGraphqlClient();
  if (process.env.VUE_APP_FEATURE_MULTIPLE_LOCALES_ENABLED) {
    return getRootSettingsInternational(
      apiClient,
      (node) => node.queries.system.rootSettings,
      { options: {} },
      {
        headers,
        silent
      }
    );
  }
  return getRootSettings(
    apiClient,
    (node) => node.queries.system.rootSettings,
    { options: {} },
    {
      headers,
      silent
    }
  );
}
function makeSaveLogRequest(input) {
  const apiClient = useGraphqlClient();
  void doSaveLog(apiClient, (node) => node.mutations.logging.saveLog, {
    options: input
  }, {
    silent: true,
    priority: RequestOptionsPriority.LOW
    // do not log errors for saveLog operation
  }).catch(noop);
}
const useRootStore = /* @__PURE__ */ defineStore("root", () => {
  const api = useGraphqlClient();
  const baseRestClient = useBaseApiClient();
  const localStorage = useLocalStorageManager();
  const fontsStore = useFontsStore();
  const { setLoggedIn } = useAuthStore();
  const I18nStore = useI18nStore();
  const { setUrlPrefixes, setLocale } = I18nStore;
  const locale = toRef(I18nStore, "locale");
  const isFontsLoaded = toRef(() => fontsStore.isFontsLoaded);
  const { setInitialCountryModuleData } = useCountryStore();
  const initUrl = ref(getLocationHref());
  const cTag = ref("");
  const slipDefaultAmount = ref(0);
  const imageCdnUrl = ref("");
  const defaultUICurrency = ref("");
  const ssoServerUrl = ref("");
  const isDevIP = ref(getBuiltinConfig().isNT);
  const isAppMainContentLoadedInner = ref(false);
  const availableLanguagesInfo = ref([]);
  const formatMoneyLocale = ref("");
  const isContentSelectionDisabled = ref(false);
  const desktopScreenMinWidth = ref(0);
  const isAppMainContentLoaded = computed(() => isAppMainContentLoadedInner.value && isFontsLoaded.value);
  const runtimePlatform = ref(
    new URL(getLocationHref()).searchParams.get(QueryParameterName.PLATFORM)
  );
  const isDirectLink = ref(true);
  const currentLanguageName = computed(
    () => {
      var _a;
      return ((_a = availableLanguagesInfo.value.find((lang) => lang.code === locale.value)) == null ? void 0 : _a.name) || "";
    }
  );
  const availableLanguages = computed(() => availableLanguagesInfo.value.map((lang) => ({
    text: lang.name,
    value: getLanguageFlagCode(lang.code),
    selected: lang.code === locale.value,
    prefix: lang.prefix || void 0,
    code: lang.code,
    flagCode: lang.flag || getLanguageFlagCode(lang.code)
  })));
  const currentLanguageFlag = computed(() => {
    var _a;
    return ((_a = availableLanguages.value.find((language) => language.code === locale.value)) == null ? void 0 : _a.flagCode) || "";
  });
  const isLanguageSwitcherAvailable = computed(
    () => availableLanguages.value.length > 1
  );
  const setDirectLinkState = (value) => {
    isDirectLink.value = value;
  };
  const setCTag = (value) => {
    cTag.value = value;
  };
  const setSlipDefaultAmount = (value) => {
    slipDefaultAmount.value = value;
  };
  const setImageCdnUrl = (value) => {
    imageCdnUrl.value = value;
  };
  const setDefaultUICurrency = (value) => {
    defaultUICurrency.value = value;
  };
  const setSsoUrl = (value) => {
    ssoServerUrl.value = value || "";
  };
  const setDevIP = (value) => {
    isDevIP.value = value;
  };
  const setAppMainContentLoaded = () => {
    isAppMainContentLoadedInner.value = true;
  };
  const setAvailableLanguagesInfo = (info) => {
    availableLanguagesInfo.value = info;
  };
  const setFormatMoneyLocale = (value) => {
    formatMoneyLocale.value = value || "";
  };
  const setDesktopScreenMinWidth = (value) => {
    desktopScreenMinWidth.value = value ?? 0;
  };
  const setIsContentSelectionDisabled = (value) => {
    isContentSelectionDisabled.value = value;
  };
  function handleData(response) {
    setCTag(response.ctag);
    setSlipDefaultAmount(response.slipDefaultAmount ?? 0);
    setImageCdnUrl(response.imageCdnUrl);
    setSsoUrl(response.ssoServerUrl);
    setDevIP(response.isNT);
    setFormatMoneyLocale(response.formatMoneyLocale);
    setDesktopScreenMinWidth(response.desktopScreenMinWidth);
    function handleRootSettingsInternational(internationalResponse) {
      var _a;
      if (internationalResponse.countryCode && ((_a = internationalResponse.countriesData) == null ? void 0 : _a.length)) {
        setInitialCountryModuleData({
          countryCode: internationalResponse.countryCode,
          countriesData: internationalResponse.countriesData
        });
      }
      setUrlPrefixes({
        currentPrefix: internationalResponse.urlLocale || "",
        replacedPrefix: internationalResponse.replacedUrlPart || ""
      });
      if (internationalResponse.currentLanguageTag) {
        assert(
          internationalResponse.currentLanguageTag.includes("_"),
          `language tag has wrong format: ${internationalResponse.currentLanguageTag}`
        );
        setLocale(internationalResponse.currentLanguageTag);
      }
      assert(isArray(internationalResponse.availableLanguages), "availableLanguages must be an array");
      setAvailableLanguagesInfo(internationalResponse.availableLanguages || []);
    }
    if (process.env.VUE_APP_FEATURE_MULTIPLE_LOCALES_ENABLED) {
      handleRootSettingsInternational(response);
    } else {
      setLocale(Locale.RU_RU);
    }
    if (response.defaultUICurrency) {
      setDefaultUICurrency(response.defaultUICurrency);
    }
    setLoggedIn(response.isCustomerLoggedIn, true);
  }
  async function fetchData(silent) {
    return handleData(await makeRootSettingsRequest(silent));
  }
  async function log(input) {
    const isLoggable = isOnline() && (isDevIP.value || input.level !== LogLevel.DEBUG);
    if (isLoggable) {
      requestIdleCallback(() => {
        makeSaveLogRequest(input);
      });
    }
    return Promise.resolve();
  }
  function setInitialApiSettings() {
    const clientApi1Url = api.getBaseUrl();
    const clientApi2Url = baseRestClient.getBaseUrl();
    const clientApi3Url = baseRestClient.getBaseUrl();
    const savedApi1Url = localStorage.getItem(ApiKeys.api1Url);
    const savedApi2Url = localStorage.getItem(ApiKeys.api2Url);
    const savedApi3Url = localStorage.getItem(ApiKeys.api3Url);
    if (savedApi1Url && clientApi1Url !== savedApi1Url) {
      api.setBaseUrl(savedApi1Url);
    }
    if (savedApi2Url && clientApi2Url !== savedApi2Url) {
      baseRestClient.setBaseUrl(savedApi2Url);
    }
    if (savedApi3Url && clientApi3Url !== savedApi3Url) {
      baseRestClient.setBaseUrl(savedApi3Url);
    }
  }
  function setAppAdHeader(value) {
    setApiHeaders({ "x-app-ad": value });
  }
  function updateApiSettings(apiUrlSettings) {
    api.setBaseUrl(apiUrlSettings.api1Url);
    baseRestClient.setBaseUrl(apiUrlSettings.api2Url);
    baseRestClient.setBaseUrl(apiUrlSettings.api3Url);
    localStorage.setItem(ApiKeys.api1Url, apiUrlSettings.api1Url);
    localStorage.setItem(ApiKeys.api2Url, apiUrlSettings.api2Url);
    localStorage.setItem(ApiKeys.api3Url, apiUrlSettings.api3Url);
  }
  useSyncState(async (silent) => {
    await fetchData(silent);
  }, "root");
  function initRootStore() {
    if (process.env.VUE_APP_PLATFORM_WEB) {
      const appAdHeader = getAppAdHeader();
      if (appAdHeader) {
        setAppAdHeader(appAdHeader);
      }
    }
    setInitialApiSettings();
  }
  void initRootStore();
  return {
    // state/getters
    cTag,
    slipDefaultAmount,
    imageCdnUrl,
    defaultUICurrency,
    ssoServerUrl,
    isDevIP,
    isAppMainContentLoaded,
    formatMoneyLocale,
    isContentSelectionDisabled,
    desktopScreenMinWidth,
    currentLanguageName,
    currentLanguageFlag,
    availableLanguages,
    isLanguageSwitcherAvailable,
    initUrl,
    isDirectLink: readonly(isDirectLink),
    runtimePlatform: readonly(runtimePlatform),
    // former mutations, exported only for tests (is it reasonable?)
    setCTag,
    setImageCdnUrl,
    setDefaultUICurrency,
    setSsoUrl,
    setDevIP,
    // actions/mutations
    setDirectLinkState,
    setIsContentSelectionDisabled,
    fetchData,
    handleData,
    log,
    setAppAdHeader,
    updateApiSettings,
    setAppMainContentLoaded
  };
});
export {
  doRootSettingsRequest,
  makeRootSettingsRequest,
  useRootStore
};
