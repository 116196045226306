import { getQueryManager } from "@leon-hub/query-manager";
let queryManager;
function useQueryManager() {
  if (!queryManager) {
    queryManager = getQueryManager();
  }
  return queryManager;
}
export {
  useQueryManager
};
