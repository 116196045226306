import { defineStore } from "pinia";
import { ref, computed, watch } from "vue";
import { isValidJsonSchema, assert } from "@leon-hub/guards";
import { useAnalyticsCommonDataStore } from "@core/analytics";
function isCountriesData(uiSchema) {
  return isValidJsonSchema(uiSchema, {
    type: "array",
    items: {
      type: "object",
      properties: {
        code: {
          type: "string"
        },
        name: {
          type: "string"
        },
        phonePrefix: {
          type: "number"
        },
        popular: {
          type: "boolean"
        }
      },
      minItems: 1
    }
  });
}
const useCountryStore = /* @__PURE__ */ defineStore("country", () => {
  const countriesData = ref([
    {
      code: process.env.VUE_APP_DEFAULT_COUNTRY_CODE ?? "",
      name: "",
      phonePrefix: Number(process.env.VUE_APP_DEFAULT_PHONE_PREFIX ?? "0"),
      popular: false
    }
  ]);
  const countryCode = ref(process.env.VUE_APP_DEFAULT_COUNTRY_CODE ?? "");
  const geoIpCountryCode = ref(process.env.VUE_APP_DEFAULT_COUNTRY_CODE ?? "");
  const computedCountryCode = computed(() => {
    const countryData = countriesData.value.find(
      (country) => country.code === countryCode.value
    );
    if (countryData === void 0) {
      return countriesData.value[0].code;
    }
    return countryCode.value;
  });
  const currentCustomerCountryData = computed(() => {
    let countryData = countriesData.value.find(
      (country) => country.code === countryCode.value
    );
    if (!countryData) {
      countryData = countriesData.value.find(
        (country) => country.code === process.env.VUE_APP_DEFAULT_COUNTRY_CODE
      );
    }
    return countryData ?? countriesData.value[0];
  });
  const setInitialCountryModuleData = (initialData) => {
    assert(isCountriesData(initialData.countriesData), "countriesData should have proper structure");
    assert(initialData.countryCode.length > 0, "countryCode length should be more than 0");
    countriesData.value = [...initialData.countriesData];
    countryCode.value = initialData.countryCode;
    geoIpCountryCode.value = initialData.countryCode;
  };
  const setCountryCode = (code) => {
    countryCode.value = code;
  };
  const analyticsStore = useAnalyticsCommonDataStore();
  watch(countryCode, (newValue) => {
    analyticsStore.setCountry(newValue);
  }, { immediate: true });
  return {
    // State
    countriesData,
    geoIpCountryCode,
    // Getters
    rootSettingsCountryCode: countryCode,
    countryCode: computedCountryCode,
    currentCustomerCountryData,
    // Actions
    setInitialCountryModuleData,
    setCountryCode
  };
});
export {
  useCountryStore
};
