import type { RestClient } from '@leon-hub/api';
import { QueryParameterName } from '@leon-hub/query-manager';
import { getQueryParams } from '@leon-hub/service-locator-env';

import { DefaultRestApiClient } from '@core/app-rest-client';

export function createBaseApiClient(): RestClient {
  const baseApiClient = new DefaultRestApiClient();
  if (getQueryParams()[QueryParameterName.TEST_CASE] === 'performance') {
    baseApiClient.setDefaultCacheTtl(Infinity);
  }
  return baseApiClient;
}
